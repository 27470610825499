import sanitize from 'sanitize-html';

import type { IFrame } from 'sanitize-html';

import EnvSettings from '../api/EnvSettings';
import FeatureFlags from '../api/FeatureFlags';

export interface ExtraOptions {
  addAllowedTags?: string[];
  addAllowedSchemes?: string[];
  addAllowedAttributes?: {
    [x: string]: string[];
  };
  disallowedTagsMode?: 'escape' | 'discard' | 'recursiveEscape' | undefined;
  selfClosing?: string[];
}

export const sanitizeHTML = (html: string, extras?: ExtraOptions | undefined, type?: string) => {
  const settings =
    type !== 'chat' ? EnvSettings.getSettings().SANITIZATION.data : EnvSettings.getSettings().CHATSANITIZATION.data;

  const settingsData = typeof settings === 'string' ? JSON.parse(settings) : settings;

  if (extras?.addAllowedTags) {
    settingsData.allowedTags = [...settingsData.allowedTags, ...extras.addAllowedTags];
  }
  if (extras?.addAllowedSchemes) {
    settingsData.allowedSchemes = [...settingsData.allowedSchemes, ...extras.addAllowedSchemes];
  }
  if (extras?.addAllowedAttributes) {
    settingsData.allowedAttributes = {
      ...settingsData.allowedAttributes,
      ...extras?.addAllowedAttributes
    };
  }
  if (extras?.disallowedTagsMode) {
    settingsData.disallowedTagsMode = extras.disallowedTagsMode;
  }
  if (extras?.selfClosing) {
    settingsData.selfClosing = [...settingsData.selfClosing, ...extras.selfClosing];
  }

  if (FeatureFlags.isFlagOn('ENABLE_LINK_PARSE')) {
    settingsData.allowedTags = [...settingsData.allowedTags, 'a'];
    settingsData.allowedAttributes = {
      ...settingsData.allowedAttributes,
      a: ['target', 'href']
    };
  }

  if (!FeatureFlags.isFlagOn('ENABLE_IMG_PARSE')) {
    settingsData.allowedTags = settingsData.allowedTags.filter((tag: string) => {
      return tag !== 'img';
    });
    settingsData.selfClosing = settingsData.selfClosing.filter((tag: string) => {
      return tag !== 'img';
    });
    settingsData.allowedAttributes = {
      ...settingsData.allowedAttributes,
      a: ['target', 'href']
    };
  }

  // Remove spans containing only a zero-width space (ZWSP)
  settingsData.exclusiveFilter = (frame: IFrame) => {
    return frame.tag === 'span' && frame.text === '\n\u200B';
  };

  /**
   * Description
   * If incoming data is html content we should parse content body only
   */
  let htmlToParse: string = html;
  if (html.search(/<html/i) === 0) {
    const bodyIndex = htmlToParse.search(/<body/i);
    const lastClosingHtml = htmlToParse.search(/<\/html>/i);

    if (bodyIndex !== -1) {
      htmlToParse = htmlToParse.slice(bodyIndex).slice(0, lastClosingHtml);
    } else if (lastClosingHtml !== -1) {
      htmlToParse = htmlToParse.slice(0, lastClosingHtml).replace(/<html+?>/i, '');
    }
  }

  return sanitize(htmlToParse, settingsData);
};

export const removeTrailingLineBreaks = (html: string): string => {
  return html.replace(/(<br>\s*)+$/, '');
};

export const appendNewlinesToElements = (html: string, elements: string[]): string => {
  const group = elements.map((e) => `</${e}>`).join('|');
  const regex = new RegExp(`(${group})`, 'g');
  return html.replaceAll(regex, '$1\r\n');
};

export const lineBreaksToNewlines = (html: string): string => {
  return html.replaceAll('<br>', '\r\n');
};

export const newlinesToLineBreaks = (html: string): string => {
  if (html.includes('\n<br>')) {
    return html.replace(/\n<br>/gm, '<br/>');
  }

  return html.replace(/(?:\r\n|\r|\n)/gm, '<br/>');
};

export const decodeHtml = (encodedtext: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = encodedtext;
  return txt.value;
};

export const removeHTMLTags = (html: string, args?: { except?: string[] }) => {
  html = removeTrailingLineBreaks(html);

  const selfClosing: string[] = [];
  if (args?.except?.includes('br')) {
    selfClosing.push('br');
  } else {
    html = lineBreaksToNewlines(html);
  }

  return sanitize(decodeHtml(html), {
    allowedTags: args?.except ?? [],
    allowedAttributes: {},
    allowedSchemes: [],
    selfClosing: selfClosing,
    disallowedTagsMode: 'discard'
  });
};

const RECONNECT_TIMEOUT = 1000;
const MAX_RECONNECT_TIMEOUT = 30000;

interface IntegrationPayload {
  AgentId: string;
  AgentName: string;
  CallerNumberUnRestricted: string;
  CallId?: string;
}

class HolviWebsocketIntegration {
  private static socket: WebSocket;
  private static url: string;
  private static wsConnectionAttempts = 0;
  public static isConnected = false;

  public static init(url: string) {
    this.url = url;
    this.connect();
  }

  private static connect() {
    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
      this.wsConnectionAttempts = 0;
      console.info('[HolviWebsocketIntegration] connected:', this.url);
      this.isConnected = true;
    };

    this.socket.onerror = (error) => {
      console.error('[HolviWebsocketIntegration] onerror:', error);
      this.socket.close();
    };

    this.socket.onclose = () => {
      this.isConnected = false;
      this.reconnect();
    };
  }

  private static reconnect() {
    const retryDelay = Math.min(Math.pow(2, this.wsConnectionAttempts) * RECONNECT_TIMEOUT, MAX_RECONNECT_TIMEOUT);
    this.wsConnectionAttempts++;

    console.info(`[HolviWebsocketIntegration] reconnecting in ${retryDelay / 1000}s`);
    setTimeout(() => this.connect(), retryDelay);
  }

  public static sendMessage(payload: IntegrationPayload) {
    this.socket.send(JSON.stringify(payload));
  }
}

export default HolviWebsocketIntegration;

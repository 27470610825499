import { t } from 'i18next';
import iziToast from 'izitoast';
import moment from 'moment';

import type {
  Category,
  Channel,
  ChatStatus,
  PersonalData,
  Priority,
  RealtimeEventType,
  ResponseTemplate,
  Suggestion,
  Tag,
  TicketType,
  UserWithProfile
} from '@eeedo/types';
import type { Middleware, MiddlewareAPI, Store } from 'redux';
import type { Socket } from 'socket.io-client';

import { FETCH_PERSONAL_DATA_SUCCESS } from 'src/actions';
import { logout, refreshToken } from 'src/actions/authActions';
import { createCategorySuccess, deleteCategorySuccess, patchCategorySuccess } from 'src/actions/categoryActions';
import { patchChannelSuccess } from 'src/actions/channelActions';
import { fetchChats, loadChatSuccess } from 'src/actions/chatActions';
import { fetchPhoneConfigurations } from 'src/actions/configActions';
import { fetchEIdentifications } from 'src/actions/eIdentificationActions';
import { deleteEntityTagsSuccess, updateEntityTagsSuccess } from 'src/actions/entityTagsActions';
import { fetchFilterPresets } from 'src/actions/filterPresetsActions';
import { fetchInfoPages } from 'src/actions/infoPagesActions';
import { removeInfopageFromInfopagelist, updateInfoPageInList } from 'src/actions/infoPagesActionsRTK';
import { showDisconnectedNotification } from 'src/actions/notificationsActions';
import { addPrioritiesSuccess, patchPrioritySuccess } from 'src/actions/priorityActions';
import {
  createAutoSuggestionSuccess,
  deleteAutoSuggestionSuccess,
  updateAutoSuggestionSuccess
} from 'src/actions/suggestionActions';
import { addHighlightToTab, closeTab } from 'src/actions/tabActionsRTK';
import { createTagSuccess, deleteTagSuccess, patchTagSuccess } from 'src/actions/tagActions';
import { closeTicketTabAndNavigate, fetchTicket, fetchTickets } from 'src/actions/ticketsActions';
import {
  removeTicketFromDetailed,
  removeTicketFromTicketlist,
  updateTicketInTicketlist
} from 'src/actions/ticketsActionsRTK';
import { loadPersonalDataSuccess } from 'src/actions/userActions';
import { fetchWebhooks } from 'src/actions/webhooksActions';
import { updateWorkStatus } from 'src/actions/workStatusActions';
import SocketInstance from 'src/realTimeNotifications';
import { updateChatVisitorTypeStatusSuccess } from 'src/reducers/chatTypeStatusReducer';
import { createTemplateSuccess, deleteTemplateSuccess, patchTemplateSuccess } from 'src/reducers/templatesReducer';
import { transcriptionsUpdated } from 'src/reducers/transcriptionsReducer';
import { removeTicketTypeSuccess, updateTicketTypeSuccess } from 'src/reducers/typesReducer';
import { fetchUserSuccess } from 'src/reducers/usersListReducer';
import { StaticTabs } from 'src/types/TicketList';
import { Roles } from 'src/types/User';
import NotificationHandler from 'src/Utilities/NotificationHandler';
import { userToNumericalId } from 'src/Utilities/users';

import type { UpdateWorkStatusData } from 'src/actions/workStatusActions';
import type { TranscriptionMessage } from 'src/Components/Case/Widget/transcriptionsWidgetTypes';
import type { ChatStatusData } from 'src/types/ChatTypeStatus';
import type { ContentTypes } from 'src/types/ContentTypes';
import type { State } from 'src/types/initialState';
import type { ThunkAppDispatch } from 'src/types/store';
import type { TicketListTicket } from 'src/types/Ticket';

interface TicketRealtime extends Omit<TicketListTicket, 'delegates'> {
  type: ContentTypes;
  deprecated: number;
}
interface ContentUpdatedData {
  ticketId: string;
  ticket: TicketRealtime;
  UID: number;
  lastAckedMessage: number;
  messageIdentifier: string;
}
type API = MiddlewareAPI<ThunkAppDispatch, State>;

const handleDeprecated = (ticket: TicketRealtime, api: API) => {
  const isTabOpen = api
    .getState()
    .ticketTabs.map((t) => t.id)
    .includes(ticket.id);

  if (isTabOpen) {
    api.dispatch(closeTab(ticket.id));
    api.dispatch(removeTicketFromDetailed(ticket.id));
  }

  api.dispatch(removeTicketFromTicketlist({ ticketId: ticket.id, id: StaticTabs.MAIN_VIEW }));
};

const handleContentByContentType = (data: ContentUpdatedData, api: API) => {
  const ticket = data.ticket;

  switch (ticket.type) {
    case 'infopage':
      api.dispatch(updateInfoPageInList(ticket, StaticTabs.MAIN_VIEW));
      break;
    case 'task':
      api.dispatch(updateTicketInTicketlist({ ticket, id: StaticTabs.MAIN_VIEW }));
      break;
    default:
      console.error('This type does not exist', data);
      break;
  }
};

export const socketInitMiddleware: Middleware = (api: API) => (next) => (action) => {
  const returnValue = next(action);
  const NotificationHandlerInstance = new NotificationHandler(api as Store);

  if (FETCH_PERSONAL_DATA_SUCCESS === action.type) {
    SocketInstance.initialize(action.payload.UID, {
      disconnect: () => (reason: Socket.DisconnectReason) => {
        if (reason === 'io server disconnect' || reason === 'io client disconnect') {
          if (localStorage.getItem('loggedIn') && SocketInstance.isInitialized) {
            api.dispatch(logout());
          }
        } else {
          // Disconnected due to network issues or service inaccessibility
          api.dispatch(refreshToken());
        }

        SocketInstance.socket.io.opts.query = {
          UID: SocketInstance._userId,
          ...(!!localStorage.getItem('lastAckedMessage') && {
            lastAckedMessage: localStorage.getItem('lastAckedMessage')
          })
        };
        api.dispatch(showDisconnectedNotification(true));
      },

      connect: () => () => {
        localStorage.setItem('lastAckedMessage', String(moment().valueOf()));
        api.dispatch(showDisconnectedNotification(false));
      },

      connect_error: () => () => {
        api.dispatch(refreshToken());
        api.dispatch(showDisconnectedNotification(true));
      },

      contentUpdated: (acknowledgeMessage) => (data: ContentUpdatedData) => {
        const { ticketId: updatedTicketId, ticket, UID, lastAckedMessage, messageIdentifier } = data;
        const type = ticket?.type;
        const reduxState = api.getState();
        const loggedInUserUID = reduxState.userData.UID;
        localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);

        const detailedTicketToRefresh = reduxState.detailedTickets.find((ticket) => ticket.id === updatedTicketId);
        const loggedInUserData = reduxState.usersList.usersList.find((user) => user.UID === loggedInUserUID);
        const hasPermissionToShowOnList =
          loggedInUserData &&
          (!Roles.isAnyOf(loggedInUserData.role.id, Roles.Delegated, Roles.DelegatedStrict) ||
            ticket.delegatedTo?.includes(loggedInUserUID));

        if (detailedTicketToRefresh && !ticket.deprecated) {
          api.dispatch(
            fetchTicket({
              id: updatedTicketId,
              closeTicketAfterFail: false,
              shouldActivateTicket: false,
              forceFetch: true,
              type
            })
          );
        }

        if (
          userToNumericalId(loggedInUserUID) !== UID &&
          reduxState.ticketTabs.find((tab) => tab.id === updatedTicketId)
        ) {
          api.dispatch(addHighlightToTab(updatedTicketId));
        }

        if (ticket?.deprecated === 1) {
          handleDeprecated(ticket, api);
        } else if (hasPermissionToShowOnList) {
          handleContentByContentType(data, api);
        }

        NotificationHandlerInstance.handleTicket(ticket);
        acknowledgeMessage?.(messageIdentifier);
      },

      personalDataUpdated:
        (acknowledgeMessage) =>
        (payload: {
          data: { resource: PersonalData; eventType: Extract<RealtimeEventType, 'update'> };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const {
            data: { resource, eventType },
            lastAckedMessage,
            messageIdentifier
          } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          if (eventType === 'update') {
            api.dispatch(loadPersonalDataSuccess(resource));
          }

          acknowledgeMessage?.(messageIdentifier);
        },

      transcriptionsUpdated: () => (payload: { contentId: string; message: TranscriptionMessage }) => {
        api.dispatch(transcriptionsUpdated({ contentId: payload.contentId, message: payload.message }));
      },

      responseTemplatesUpdated:
        (acknowledgeMessage) =>
        (payload: {
          data: { resource: ResponseTemplate; eventType: RealtimeEventType };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { lastAckedMessage, messageIdentifier } = payload;
          const { resource, eventType } = payload.data;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);

          switch (eventType) {
            case 'create': {
              api.dispatch(createTemplateSuccess(resource));
              break;
            }
            case 'update': {
              api.dispatch(patchTemplateSuccess(resource));
              break;
            }
            case 'delete': {
              api.dispatch(deleteTemplateSuccess({ id: resource._id as string }));
              break;
            }
          }
          acknowledgeMessage?.(messageIdentifier);
        },

      updateTicketsChatVisitorTypeStatus:
        (acknowledgeMessage) =>
        (payload: { message: string; data: ChatStatusData; lastAckedMessage: number; messageIdentifier: string }) => {
          try {
            const { data, lastAckedMessage, messageIdentifier } = payload;

            localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
            api.dispatch(updateChatVisitorTypeStatusSuccess(data));
            acknowledgeMessage?.(messageIdentifier);
          } catch (error) {
            console.error('updateTicketsChatVisitorTypeStatus: ', error);
          }
        },

      tagsUpdated:
        (acknowledgeMessage) =>
        (payload: {
          data: {
            resource: Tag;
            eventType: RealtimeEventType;
          };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { lastAckedMessage, messageIdentifier, data } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          switch (data.eventType) {
            case 'create': {
              api.dispatch(createTagSuccess(data.resource));
              break;
            }
            case 'update': {
              api.dispatch(patchTagSuccess(data.resource));
              break;
            }
            case 'delete': {
              api.dispatch(deleteTagSuccess(data.resource.id));
            }
          }
          acknowledgeMessage?.(messageIdentifier);
        },

      channelsUpdated:
        (acknowledgeMessage) =>
        (payload: {
          data: { resource: Channel; eventType: RealtimeEventType };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { lastAckedMessage, messageIdentifier } = payload;
          const { resource, eventType } = payload.data;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          switch (eventType) {
            case 'update': {
              api.dispatch(patchChannelSuccess(resource));
              break;
            }
            default:
              break;
          }
          acknowledgeMessage?.(messageIdentifier);
        },

      categoriesUpdated:
        (acknowledgeMessage) =>
        (payload: {
          data: { resource: Category; eventType: RealtimeEventType };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          switch (payload.data.eventType) {
            case 'create': {
              api.dispatch(createCategorySuccess(payload.data.resource));
              break;
            }
            case 'update': {
              api.dispatch(patchCategorySuccess(payload.data.resource));
              break;
            }
            case 'delete': {
              api.dispatch(deleteCategorySuccess(payload.data.resource.id));
              break;
            }
            default:
              break;
          }
          acknowledgeMessage?.(messageIdentifier);
        },

      caseTicketTypeChanged:
        (acknowledgeMessage) =>
        async (payload: {
          data: { caseId: number; type: string; ticketType: number };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { data, lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          const isUserHasAccessToTicketType = api
            .getState()
            .ticketTypes.find((ticketType) => ticketType.id === data.ticketType);

          if (!isUserHasAccessToTicketType) {
            switch (data.type) {
              case 'task': {
                const ticketId = `TSK${payload.data.caseId}`;
                const ticketIsOpen = api.getState().ticketTabs.filter((tab) => tab.id === ticketId).length > 0;

                if (ticketIsOpen) {
                  api.dispatch(closeTicketTabAndNavigate({ contentId: ticketId }));

                  iziToast.error({
                    title: `${t('ERROR')}!`,
                    icon: 'icon delete',
                    message: t('TICKET_CLOSED_LACK_OF_PERMISSIONS', { ticketId }),
                    timeout: 5000
                  });
                }
                api.dispatch(
                  removeTicketFromTicketlist({
                    ticketId,
                    id: StaticTabs.MAIN_VIEW
                  })
                );
                break;
              }

              case 'infopage': {
                const infopageId = `INF${payload.data.caseId}`;
                const infopageIsOpen =
                  api.getState().ticketTabs.filter((tab) => tab.id === infopageId && tab.activeTab).length > 0;

                if (infopageIsOpen) {
                  api.dispatch(closeTab(infopageId));
                  api.dispatch(removeTicketFromDetailed(infopageId));
                  iziToast.error({
                    title: `${t('ERROR')}!`,
                    icon: 'icon delete',
                    message: `Infopage ${infopageIsOpen} was closed due to the lack of permissions`,
                    timeout: 5000
                  });
                }
                api.dispatch(removeInfopageFromInfopagelist(infopageId, StaticTabs.MAIN_VIEW));
                break;
              }

              default: {
                break;
              }
            }
          }
          acknowledgeMessage?.(messageIdentifier);
        },

      statusUpdates: () => (data: UpdateWorkStatusData) => {
        api.dispatch(updateWorkStatus(data));
      },

      usersUpdated:
        (acknowledgeMessage) =>
        async (payload: {
          data: { resource: UserWithProfile; eventType: Extract<RealtimeEventType, 'create' | 'update'> };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const {
            data: { resource, eventType },
            lastAckedMessage,
            messageIdentifier
          } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          acknowledgeMessage?.(messageIdentifier);

          switch (eventType) {
            case 'create':
            case 'update': {
              api.dispatch(fetchUserSuccess({ user: resource }));
            }
          }

          const { usersList, userData } = api.getState();
          const user = usersList.usersList.find(({ UID }) => UID === userData.UID);
          if (user?.role.id === 'ROL3') {
            api.dispatch(logout());
          }
        },

      ticketTypesUpdated:
        (acknowledgeMessage) =>
        (payload: {
          data: { resource: TicketType; eventType: RealtimeEventType };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const {
            data: { resource, eventType },
            lastAckedMessage,
            messageIdentifier
          } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          switch (eventType) {
            case 'create':
            case 'update': {
              api.dispatch(updateTicketTypeSuccess(resource));
              break;
            }
            case 'delete': {
              api.dispatch(removeTicketTypeSuccess(resource.id));
              break;
            }
          }
          acknowledgeMessage?.(messageIdentifier);
        },

      userTicketTypeChanged:
        (acknowledgeMessage) =>
        async (payload: {
          data: { resource: TicketType; eventType: Extract<RealtimeEventType, 'create' | 'delete'> };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const {
            data: { resource, eventType },
            lastAckedMessage,
            messageIdentifier
          } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          SocketInstance.emit('updateRooms', { roomName: resource.name, type: eventType });

          await api.dispatch(refreshToken(false));
          switch (eventType) {
            case 'create': {
              api.dispatch(updateTicketTypeSuccess(resource));
              break;
            }
            case 'delete': {
              api.dispatch(removeTicketTypeSuccess(resource.id));
              break;
            }
          }
          api.dispatch(fetchTickets(null, StaticTabs.MAIN_VIEW));
          api.dispatch(fetchInfoPages(undefined, StaticTabs.MAIN_VIEW, false));

          acknowledgeMessage?.(messageIdentifier);
        },

      autoSuggestionsUpdated:
        (acknowledgeMessage) =>
        (payload: {
          data: {
            resource: Suggestion;
            eventType: RealtimeEventType;
          };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { lastAckedMessage, messageIdentifier, data } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);

          switch (data.eventType) {
            case 'create': {
              api.dispatch(createAutoSuggestionSuccess({ suggestion: data.resource }));
              break;
            }
            case 'update': {
              api.dispatch(updateAutoSuggestionSuccess({ suggestion: data.resource }));
              break;
            }
            case 'delete': {
              api.dispatch(deleteAutoSuggestionSuccess({ id: data.resource.id }));
            }
          }
          acknowledgeMessage?.(messageIdentifier);
        },

      chatStatusesUpdated:
        (acknowledgeMessage) =>
        (payload: {
          data: { resource?: ChatStatus; eventType: Extract<RealtimeEventType, 'create' | 'update'> | 'updateAll' };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const {
            data: { resource, eventType },
            lastAckedMessage,
            messageIdentifier
          } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          switch (eventType) {
            case 'updateAll': {
              api.dispatch(fetchChats());
              break;
            }
            case 'create':
            case 'update': {
              api.dispatch(loadChatSuccess(resource!));
              break;
            }
          }
          acknowledgeMessage?.(messageIdentifier);
        },

      prioritiesUpdated:
        (acknowledgeMessage) =>
        (payload: {
          data: { priority: Priority; eventType: Extract<RealtimeEventType, 'create' | 'update'> };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { lastAckedMessage, messageIdentifier, data } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          switch (data.eventType) {
            case 'create': {
              api.dispatch(addPrioritiesSuccess(data.priority));
              break;
            }
            case 'update': {
              api.dispatch(patchPrioritySuccess(data.priority));
              break;
            }
          }
          acknowledgeMessage?.(messageIdentifier);
        },

      eIdentificationChanged:
        (acknowledgeMessage) =>
        (payload: { data: { ticketId: number }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { data, lastAckedMessage, messageIdentifier } = payload;
          const { ticketId } = data;
          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          const reduxState = api.getState();
          const detailedTicketToRefresh = reduxState.detailedTickets.find((ticket) => ticket.id === `TSK${ticketId}`);

          if (detailedTicketToRefresh) {
            api.dispatch(fetchEIdentifications({ contentId: data.ticketId }));
          }

          acknowledgeMessage?.(messageIdentifier);
        },

      filterPresetsChanged:
        (acknowledgeMessage) =>
        (payload: { data: { message: number }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;
          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          const reduxState = api.getState();
          if (!reduxState.filterPresets.loaded) {
            return;
          }

          api.dispatch(fetchFilterPresets({}));
          acknowledgeMessage?.(messageIdentifier);
        },

      entityTagsUpdated:
        (acknowledgeMessage) =>
        (payload: {
          data: { entityId: string; tagIds: number[]; eventType: 'attached' | 'detached' };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { data, lastAckedMessage, messageIdentifier } = payload;
          const { entityId, tagIds, eventType } = data;
          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);

          const reduxState = api.getState();
          const detailedTicketRelationToEntity = reduxState.detailedTickets.find(
            (ticket) => !!ticket.entities.find((entity) => entity._id === entityId)
          );

          if (detailedTicketRelationToEntity) {
            switch (eventType) {
              case 'attached': {
                api.dispatch(updateEntityTagsSuccess({ entityId, tagIds }));
                break;
              }
              case 'detached': {
                api.dispatch(deleteEntityTagsSuccess({ entityId, tagIds }));
                break;
              }
              default: {
                console.error('Unknown event type under entityTagsUpdated handler');
                break;
              }
            }
          }

          acknowledgeMessage?.(messageIdentifier);
        },

      entityAttached:
        (acknowledgeMessage) =>
        (payload: {
          data: { contentId: number; entityId: string };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { lastAckedMessage, messageIdentifier } = payload;
          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          acknowledgeMessage?.(messageIdentifier);
        },

      entityDetached:
        (acknowledgeMessage) =>
        (payload: {
          data: { contentId: number; entityId: string };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { lastAckedMessage, messageIdentifier } = payload;
          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          acknowledgeMessage?.(messageIdentifier);
        },

      webhooksUpdated:
        (acknowledgeMessage) =>
        async (payload: { data: { message: string }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          acknowledgeMessage?.(messageIdentifier);

          api.dispatch(fetchWebhooks());
        },

      phoneConfigurationsUpdated:
        (acknowledgeMessage) =>
        async (payload: { data: { message: string }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          acknowledgeMessage?.(messageIdentifier);

          api.dispatch(fetchPhoneConfigurations());
        }
    });
  }

  return returnValue;
};
